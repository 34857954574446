import { Inject, Component, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map, takeUntil, take } from 'rxjs/operators';
import { UpdateUser } from '@app/auth/_store/actions/auth.actions';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { IUser } from '@app/models';
import { Observable, Subject } from 'rxjs';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { TariffPlanModalComponent } from '../tariff-plan-modal/tariff-plan-modal.component';
import { TrackerTutorialModalComponent } from '../tracker-tutorial-modal/tracker-tutorial-modal.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { checkRole } from '@app/helpers/check-role';
import { SubscriptionsSubService } from '@app/modules/settings/services/subscriptions-sub.service';
import { LoadCompanySettingAction, LoadCompanySubSetting, LoadSubscriptionSubs } from '@app/modules/settings/store/actions/company-setting.actions';
import {
  selectCompanySubSetting,
  selectSubscriptionJuscourt,
  selectCompanySetting
} from '@app/modules/settings/store/selectors/company-setting.selector';
import { SubscriptionSub } from '@app/models/subscription-sub';
import { CompanySettingService } from '@app/modules/settings/services/company-setting.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-apps-modal',
    templateUrl: './apps-modal.component.html',
    styleUrls: ['./apps-modal.component.scss'],
    standalone: false,
})
export class AppsModalComponent implements OnInit, OnDestroy {
  private selectCurrentUser$: Observable<IUser> = this.store.pipe(select(selectCurrentUser));

  currentUser: IUser;
  private unsubscribe = new Subject<void>();
  isCheckJuscourt;
  isCheckDocusign;
  activeJuscourt: SubscriptionSub;
  companySettings;

  constructor(
    public dialogRef: MatDialogRef<AppsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store<IAppState>,
    private dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly translate: TranslateService,
    private readonly companySettingsService: CompanySettingService,
    public subscriptionsSubService: SubscriptionsSubService
  ) {
    this.selectCurrentUser$
      .pipe(
        map((currentUser: IUser) => {
          if (currentUser !== null) {
            this.currentUser = currentUser;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadCompanySubSettings();
    this.getCompanySettingData();
  }

  deleteApp(name) {
    switch (name) {
      case 'outlook':
        {
          const settings = { outlookAccount: { enabled: false }, id: this.currentUser.settings[0].id };
          if (this.currentUser.settings[0].outlookAccount.enabled) {
            this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
          }
        }
        break;
      case 'viber':
        {
          const settings = { viberAccount: { enabled: false }, id: this.currentUser.settings[0].id };
          if (this.currentUser.settings[0].viberAccount.enabled) {
            this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
          }
        }
        break;
      case 'telegram':
        {
          const settings = { telegramAccount: { enabled: false }, id: this.currentUser.settings[0].id };
          if (this.currentUser.settings[0].telegramAccount.enabled) {
            this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
          }
        }
        break;
      case 'google':
        {
          const settings = {
            settings: [{ googleAccount: { enabled: false }, id: this.currentUser.settings[0].id }]
          };
          if (this.currentUser.settings[0].googleAccount.enabled) {
            this.store.dispatch(new UpdateUser(settings, this.currentUser.id));
          }
        }
        break;
      case 'juscourtTracker': {
        this.deleteJuscourt();
        break;
      }
      case 'docuSign': {
        this.deleteDocusign();
        break;
      }
      case 'zapier': {
        this.installZapier();
        break;
      }
      case 'accountingOrders': {
        this.removeOrderAndMandatory();
        break;
      }
      case 'enforcementProceedings': {
        this.removeEnforcementProceedings();
        break;
      }
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  customHref(url) {
    switch (url) {
      case 'juscourt':
        this.installJuscourt();
        break;
      case 'docusign':
        this.installDocusign();
        break;
      case 'accountingOrders': // admin
        this.enableOrderAndMandatory();
        break;
      case 'enforcementProceedings':
        this.enableEnforcementProceedings();
        break;
      case 'https://zapier.com/app/dashboard': {
        this.installZapier();
        break;
      }
      default:
        // location.href = url;
        window.open(url, "_blank");
        break;
    }
  }

  installJuscourt() {
    if (checkRole(['ROLE_SITE_SUBSCRIPTIONS_MANAGER'])) {
      if (this.activeJuscourt) {
        this.subscriptionsSubService
          .createCompanySubSettings({
            subType: this.activeJuscourt.subType,
            company: this.companySettings.id
          })
          .subscribe(() => {
            this.store.dispatch(new LoadCompanySubSetting());
            this.store.dispatch(new LoadSubscriptionSubs());
          });
      } else {
        this.dialogRef.close();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['default-mat-dialog', 'tariff-plan-modal'];
        this.dialog
          .open(TariffPlanModalComponent, dialogConfig)
          .afterClosed()
          .subscribe(res => {
            if (res) {
              this.juscourtTrackerTutorialModal();
            }
          });
      }
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'Зверніться до адміністратора',
        mainTxt: `Лише адміністратор має можливість встановити плагін для всіх користувачів системи. Будь ласка,
        зверніться до нього для встановлення Juscourt Tracker`,
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  deleteJuscourt() {
    if (checkRole(['ROLE_SITE_SUBSCRIPTIONS_MANAGER'])) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        yesButtonTxt: 'deleteYesButtonTxt',
        noButtonTxt: 'deleteNoButtonTxt',
        title: 'deleteTitleTxt',
        mainTxt: 'deleteTxt'
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result === true) {
            this.subscriptionsSubService.removeCompanySubSettings(this.isCheckJuscourt.id).subscribe(() => {
              this.data.modalData.status = false;
              this.store.dispatch(new LoadCompanySubSetting());
              this.store.dispatch(new LoadSubscriptionSubs());
            });
          } else {
            dialogRef.close();
          }
        });
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'Зверніться до адміністратора',
        mainTxt: `Лише адміністратор має можливість встановити плагін для всіх користувачів системи. Будь ласка,
        зверніться до нього для встановлення Juscourt Tracker`,
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  installDocusign() {
    if (checkRole(['ROLE_SITE_ADMIN'])) {
      this.subscriptionsSubService
        .createCompanySubSettings({
          subType: 'docusign',
          company: this.companySettings.id
        })
        .subscribe(res => {
          this.store.dispatch(new LoadCompanySubSetting());
          this.store.dispatch(new LoadSubscriptionSubs());
        });
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'juscourtTracker.contactAdministratorTitle',
        mainTxt: this.translate.instant('juscourtTracker.contactAdministratorDesc', {app: `"Sign Documents Swiftly"`}),
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  deleteDocusign() {
    if (checkRole(['ROLE_SITE_SUBSCRIPTIONS_MANAGER'])) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        yesButtonTxt: 'deleteYesButtonTxt',
        noButtonTxt: 'deleteNoButtonTxt',
        title: 'deleteTitleTxt',
        mainTxt: 'deleteTxt'
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result === true) {
            this.subscriptionsSubService.removeCompanySubSettings(this.isCheckDocusign.id).subscribe(() => {
              this.data.modalData.status = false;
              this.store.dispatch(new LoadCompanySubSetting());
              this.store.dispatch(new LoadSubscriptionSubs());
            });
          } else {
            dialogRef.close();
          }
        });
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'juscourtTracker.contactAdministratorTitle',
        mainTxt: this.translate.instant('juscourtTracker.contactAdministratorDesc', {app: `"Sign Documents Swiftly"`}),
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  enableOrderAndMandatory() {
    if (checkRole(['ROLE_SITE_ADMIN'])) {
      this.companySettingsService
        .updateCompanySettings({ orderAndMandatoryEnabled: true, id: this.companySettings.id })
        .subscribe(() => {
          this.store.dispatch(new LoadCompanySettingAction());
          this.toastrService.success(this.translate.instant('itemEdited'));
        })
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'juscourtTracker.contactAdministratorTitle',
        mainTxt: this.translate.instant('juscourtTracker.contactAdministratorDesc', { app: `"${this.translate.instant('apps.accountingOrdersModalTitle')}"` }),
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }

  }

  removeOrderAndMandatory() {
    if (checkRole(['ROLE_SITE_ADMIN'])) {
      this.companySettingsService
        .updateCompanySettings({ orderAndMandatoryEnabled: false, id: this.companySettings.id })
        .subscribe(() => {
          this.store.dispatch(new LoadCompanySettingAction());
          this.toastrService.success(this.translate.instant('itemEdited'));
        })
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'juscourtTracker.contactAdministratorTitle',
        mainTxt: this.translate.instant('juscourtTracker.contactAdministratorDesc', { app: `"${this.translate.instant('apps.accountingOrdersModalTitle')}"` }),
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  enableEnforcementProceedings() {
    if (checkRole(['ROLE_SITE_ADMIN'])) {
      this.companySettingsService
        .updateCompanySettings({ enforcementProceedingsEnabled: true, id: this.companySettings.id })
        .subscribe(() => {
          this.store.dispatch(new LoadCompanySettingAction());
          this.toastrService.success(this.translate.instant('itemEdited'));
        })
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'juscourtTracker.contactAdministratorTitle',
        mainTxt: this.translate.instant('juscourtTracker.contactAdministratorDesc', { app: `"${this.translate.instant('apps.enforcementProceedingsListTitle')}"` }),
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  removeEnforcementProceedings() {
    if (checkRole(['ROLE_SITE_ADMIN'])) {
      this.companySettingsService
      .updateCompanySettings({ enforcementProceedingsEnabled: false, id: this.companySettings.id })
      .subscribe(() => {
        this.store.dispatch(new LoadCompanySettingAction());
        this.toastrService.success(this.translate.instant('itemEdited'));
      })
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'juscourtTracker.contactAdministratorTitle',
        mainTxt: this.translate.instant('juscourtTracker.contactAdministratorDesc', {app: `"${this.translate.instant('apps.enforcementProceedingsListTitle')}"`}),
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  juscourtTrackerTutorialModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'tracker-tutorial-modal'];
    this.dialog.open(TrackerTutorialModalComponent, dialogConfig);
  }

  installZapier() {
    if (checkRole(['ROLE_SITE_ADMIN'])) {
      window.open('https://zapier.com/app/dashboard', '_blank');
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.panelClass = 'default-mat-dialog';

      dialogConfig.data = {
        noBtnStyle: 'blue',
        noButtonTxt: 'cannotDeleteButton',
        title: 'juscourtTracker.contactAdministratorTitle',
        mainTxt: this.translate.instant('juscourtTracker.contactAdministratorDesc', { app: `"${this.translate.instant('apps.zapierModalTitle')}"` }),
        disableYesButton: true,
        disableNoButton: false
      };

      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
  }

  loadCompanySubSettings() {
    this.store.select(selectCompanySubSetting).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadCompanySubSetting());
      } else {
        this.isCheckJuscourt = res.find(sub => sub.subType === 'juscourt');
        this.isCheckDocusign = res.find(sub => sub.subType === 'docusign');
      }
    });
    // load/find subscriptions tariff juscourt
    this.store.select(selectSubscriptionJuscourt).subscribe(res => {
      if (!res) {
        this.store.dispatch(new LoadSubscriptionSubs());
      } else {
        this.activeJuscourt = res[0];
      }
    });
  }

  getCompanySettingData() {
    this.store.pipe(select(selectCompanySetting)).subscribe(data => {
      if (data?.id) {
        this.companySettings = data;
      }
    });
  }
}

<div class="modal-app-template">
  <div class="modal-container">
    <div class="modal-header-image" [ngStyle]="{ 'background-image': 'url(' + data.modalData.image + ')' }">
      <div class="close-modal" (click)="closeModal()">
        <span class="j-icon-cross"></span>
      </div>
    </div>
    <div class="modal-app-body">
      <div class="title-block">
        <div
            [class]="'image-container ' + data.modalData.name"
            [ngStyle]="{ 'background-image': 'url(' + data.modalData.icon + ')' }"
          >
          <div class="new-app" [ngClass]="{ visible: data.modalData.new }">New!</div>
        </div>
        <div class="title-info">
          <h5>{{ data.modalData.title }}</h5>

          <div class="button-container">
            <a (click)="customHref(data.url)" *ngIf="data.modalData.status === false">{{ 'apps.install' | translate }}</a>
            <div class="installed-button" *ngIf="data.modalData.status === true" [app-tooltip]="menuTem">
              <span>{{ 'apps.installed' | translate }}</span>
            </div>
            <ng-template #menuTem>
              <div class="dropdown-wrapper docs">
                <div class="dropdown-item delete-button proceeding" (click)="deleteApp(data.modalData.name)">
                  <div class="icon-container">
                    <i class="j2-icon-remove"></i>
                  </div>
                  <span>{{ 'apps.delete' | translate }}</span>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="description-container">
        <div class="description-container-inner">
          <span class="description" [innerHTML]="data.modalData?.modalText || '<b>' + data.modalData.title + '</b>' + data.modalData.description">
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
